import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import './styles.css'
import Experience from './Experience'
import * as THREE from 'three'
import { StrictMode } from 'react'
import { useControls, button, folder, Leva } from "leva"


const root = ReactDOM.createRoot(document.querySelector('#root'))


root.render(
    <StrictMode>
        <Leva collapsed/>
        <Canvas
            gl={{
                ToneMapping: THREE.ACESFilmicToneMapping
                
            }}
            gl={{
            }}
            camera={{
                fov: 45,
                near: 0.1,
                far: 200,
                position: [3,2,6]
            }}
        >
            
            <Experience />
            </Canvas>
    </StrictMode>
)