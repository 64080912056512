import { useThree, extend, useFrame } from '@react-three/fiber'
import { Text, Html, PivotControls, OrbitControls, TransformControls, Float, MeshReflectorMaterial } from '@react-three/drei'

import { useControls, button, folder } from "leva"
import { Perf } from 'r3f-perf'



import {useRef} from 'react'

export default function Experience() {
    const { perfVisible } = useControls('Perf Visiblity', {
        perfVisible: false,
    })

    const { position, color, visible } = useControls('sphere', {
        position: {

            value: { x: -2, y: 1},
            step: 0.01
        },
        color: "#ff0000",
        visible: true,
        clickMe: button(() => {
            console.log("Okay")
        }),
        choice: { options: [ 'a', 'b','c']}
    })

    const { scale } = useControls('cube', {
        scale:
        {
            value: 1.5,
            step: 0.01,
            min: 0,
            max: 5
        }
    })
    const { camera, gl } = useThree()
    const cubeRef = useRef()
    const groupRef = useRef()
    const sphere = useRef()
    useFrame((state, delta) => {
        const angle = state.clock.elapsedTime
        state.camera.lookAt(0,0,0)
        cubeRef.current.rotation.y += delta
        // groupRef.current.rotation.y += delta

    })
    return (
        <>
            {perfVisible ? <Perf position="top-left"/> : null}
            <OrbitControls makeDefault/>
            <directionalLight position={[1, 2, 3]} intensity={1.5} />
            <ambientLight intensity={0.5} />
            <group ref={groupRef}>
                <PivotControls anchor={[0,0,0]} depthTest= { false} lineWidth={1} >
                    <mesh position={[position.x, position.y, 0]}
                        ref={sphere}
                        visible={visible}
                    >
                        <sphereGeometry />
                        <meshStandardMaterial color={color} />
                        <Html
                            position={[1,1,0]}
                            wrapperClass="label"
                            center
                            distanceFactor={3}
                            occlude={[sphere,cubeRef]}
                        >This is a sphere 👍</Html>

                    </mesh>
                </PivotControls>
                <mesh ref={cubeRef} position={[2,0,0]} rotation-y={ Math.PI * 0.25 } scale={scale} >
            <boxGeometry scale={1.5} />
            <meshStandardMaterial color="mediumpurple" />
                    </mesh>
                <TransformControls object={ cubeRef} />
            <mesh position-y={ -1 } rotation-x={ - Math.PI * 0.5} scale={10}>
                <planeGeometry  />
                    <MeshReflectorMaterial
                        blur={[1000, 1000]}
                        mixBlur={1}
                        mirror={0.75}
                />
            </mesh>
            </group>
            <Float
                speed={5}
                floatIntensity={2}
            >
                 <Text
                font="./bangers-v20-latin-regular.woff"
                fontSize={1}
                color="salmon"
                position-y={2}
                maxWidth={4}
                position-z={-3}
            > I love React 3 Fiber
            </Text>

           </Float>


            </>
    )
}